import { Transition } from '@headlessui/react'

import { useReRender } from '../use-re-render'

import { TransitionQueueHandlerProps } from './types'

export function TransitionQueueHandlerOutIn({ transitionQueueRef }: TransitionQueueHandlerProps) {
    const reRender = useReRender()

    const firstQueuedProps = transitionQueueRef.current[0].props
    const hasChildren = firstQueuedProps.children || firstQueuedProps.children === 0

    if (transitionQueueRef.current.length === 1) {
        return (
            <Transition
                {...firstQueuedProps}
                show={true}
                // Skip enter animation if no children
                enter={hasChildren ? firstQueuedProps.enter : undefined}
            />
        )
    }

    return (
        <Transition
            {...firstQueuedProps}
            show={false}
            // Skip leave animation if no children
            leave={hasChildren ? firstQueuedProps.leave : undefined}
            afterLeave={() => {
                const lastQueued =
                    transitionQueueRef.current[transitionQueueRef.current.length - 1]!
                transitionQueueRef.current = [lastQueued]
                reRender()
            }}
        />
    )
}
