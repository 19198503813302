import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function ClockOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M13 6a1 1 0 1 0-2 0v6c0 .3.1.5.3.7l3 3a1 1 0 0 0 1.4-1.4L13 11.6V6Z" />
            <path d="M12 1a11 11 0 1 0 0 22 11 11 0 0 0 0-22ZM3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z" />
        </SvgIcon>
    )
}
