import { theme } from '.'

export function getRemSize(windowWidth: number) {
    if (windowWidth < Number.parseInt(theme.screens.md)) {
        return 18
    }
    if (windowWidth < Number.parseInt(theme.screens.xl)) {
        return 20
    }
    return 22
}
