import { isValidElement, useRef } from 'react'

import { useGetMountUniqueKey } from '../use-get-mount-unique-key'

import { InternalSwitchTransitionProps, Queue } from './types'

const INITIAL_TRANSITION_KEY = 0

/**
 * The `transitionQueue` collects all props with children which aren't equal into an array in order of occurrence. It only adds new elements and is not responsible for dealing with the elements in the queue.
 * - The last queue element is always the most up-to-date one. This means that there is always at least one element in the queue.
 * - If one element is in the queue, this means that the element is in an idle state and no transition is happening.
 * - If more than one element is in the queue, this means that there is an ongoing transition.
 */
export function useTransitionQueueRef(props: InternalSwitchTransitionProps) {
    const getUniqueKey = useGetMountUniqueKey(INITIAL_TRANSITION_KEY + 1)

    /**
     * We use a ref for the queue because we need to update it during the render.
     * This way we don't need to render twice every time the parent re-renders (on the first render we get a new children reference from props and we setState, on the second we render with the new children in state).
     * The tradeoff is that updating the transition queue (e.g. when a transition ends) doesn't trigger a re-render and we need to re-render manually.
     */
    const transitionQueueRef = useRef<Queue>([{ props, key: INITIAL_TRANSITION_KEY }])

    const lastQueued = transitionQueueRef.current[transitionQueueRef.current.length - 1]!

    if (areChildrenEqual(lastQueued.props.children, props.children)) {
        lastQueued.props = props
    } else {
        // We use concat instead of push so we don't mutate the array in places where `transitionQueueRef.current` was stored in a variable.
        transitionQueueRef.current = transitionQueueRef.current.concat({
            props,
            key: getUniqueKey(),
        }) as Queue
    }

    return transitionQueueRef
}

function areChildrenEqual(oldChildren: React.ReactNode, newChildren: React.ReactNode) {
    return (
        oldChildren === newChildren ||
        (isValidElement(oldChildren) &&
            isValidElement(newChildren) &&
            oldChildren.key !== null &&
            oldChildren.key !== undefined &&
            oldChildren.key === newChildren.key)
    )
}
