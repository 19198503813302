import { useCallback, useEffect, useState } from 'react'

import { isAppHydratedRef, localStorageGet, localStorageSet } from 'src/libs/utils'

type Value = string | undefined

interface InternalState {
    key: string
    value: Value
    source: 'hydrationState' | 'localStorage' | 'change'
}

/*
Sorry for the confusing code of this hook. I've already spent too much time with this and this is the best I could come up with until now.
What this hook tries to achieve and why it is so confusing:
- If we're during the hydration phase of the app, we can't read from local storage and need to use the hydrationState instead
- We want to get the local storage value as early as possible, meaning
    - If this component gets mounted during hydration phase, we return the hydration state during first render and put the local storage value into state in an effect
    - If this component gets mounted after hydration, we get and return the value during the first render
- If the key changes in a re-render, we want to return the corresponding local storage value within that render to prevent inconsistencies
- Reading and writing to local storage is slow while simultaneously rendering UI to the user is higher prio than writing to local strage. So we want to prevent that writing to local storage blocks rendering by only writing in an effect and not in event handlers.
*/
export function useLocalStorage(
    key: string,
    hydrationState?: string
): [value: Value, setValue: (value: Value) => void] {
    const [{ key: currentKey, source, value }, setInternalState] = useState<InternalState>(() =>
        getInternalState(key, hydrationState)
    )

    // Sync React state with local storage
    useEffect(() => {
        // Get local storage value if current React state comes from hydration
        if (source === 'hydrationState') {
            const nextValue = localStorageGet(currentKey)

            if (nextValue !== value) {
                setInternalState({ key: currentKey, value: nextValue, source: 'localStorage' })
            }

            return
        }

        // Set local storage value if React state was changed within React
        if (source === 'change') {
            localStorageSet(currentKey, value)
            return
        }
    }, [source, currentKey, value])

    const setValue = useCallback(
        (value: Value) =>
            setInternalState((state) =>
                state.value === value ? state : { key: state.key, value, source: 'change' }
            ),
        []
    )

    // Update React state if key changes and return new local storage value immediately
    if (key !== currentKey) {
        const nextInternalState = getInternalState(key, hydrationState)

        setInternalState(nextInternalState)

        return [nextInternalState.value, setValue]
    }

    return [value, setValue]
}

function getInternalState(key: string, hydrationState: Value): InternalState {
    return isAppHydratedRef.current
        ? { key, value: localStorageGet(key), source: 'localStorage' }
        : { key, value: hydrationState, source: 'hydrationState' }
}
