/**
 * If user is in private mode or has storage restriction, localStorage can throw.
 * In that case we mock local storage in memory
 */
const localStorageMock: Record<string, string | undefined> = {}

export function localStorageGet(key: string) {
    try {
        return localStorage.getItem(key) ?? undefined
    } catch {
        return localStorageMock[key]
    }
}

export function localStorageSet(key: string, value: string | undefined) {
    try {
        if (value === undefined) {
            localStorage.removeItem(key)
        } else {
            localStorage.setItem(key, value)
        }
    } catch {
        localStorageMock[key] = value
    }
}
