// import clsx from 'clsx'

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function ArrowDownOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M5.3 9.3a1 1 0 0 1 1.4 0l5.3 5.29 5.3-5.3a1 1 0 1 1 1.4 1.42l-6 6a1 1 0 0 1-1.4 0l-6-6a1 1 0 0 1 0-1.42Z" />
        </SvgIcon>
    )
}

// export function ArrowLeftOutlinedIcon(props: SvgIconProps) {
//     return <ArrowDownOutlinedIcon {...props} className={clsx('rotate-90', props.className)} />
// }

// export function ArrowRightOutlinedIcon(props: SvgIconProps) {
//     return <ArrowDownOutlinedIcon {...props} className={clsx('-rotate-90', props.className)} />
// }

// export function ArrowUpOutlinedIcon(props: SvgIconProps) {
//     return <ArrowDownOutlinedIcon {...props} className={clsx('rotate-180', props.className)} />
// }
