import { useEffect, useRef } from 'react'

export function useAutoFocusedRef<Element extends HTMLElement>(isActive = true) {
    const elementRef = useRef<Element>(null)

    useEffect(() => {
        if (isActive) {
            elementRef.current?.focus()
        }
    }, [isActive])

    return elementRef
}
