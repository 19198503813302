import Head from 'next/head'

import { NEXT_PUBLIC_VERCEL_URL } from 'src/libs/config'
import { pageHomeAbstractImage } from 'src/libs/assets'

interface HtmlHeadProps {
    title?: string
    description?: string
    imageUrl?: string
    noImageFallback?: boolean
    children?: React.ReactNode
}

export function HtmlHead({
    title,
    description,
    imageUrl,
    noImageFallback,
    children,
}: HtmlHeadProps) {
    return (
        // DOM elements need to be direct children of Head or wrapped in maximum of one Fragment. Don't render React components here!
        <Head>
            {title &&
                renderTitle(
                    title.toLowerCase().includes('laserfocus') ? title : title + ' | Laserfocus'
                )}
            {description && renderDescription(description)}
            {imageUrl
                ? renderImage(imageUrl)
                : !noImageFallback &&
                  renderImage(pageHomeAbstractImage.src || (pageHomeAbstractImage as any))}
            {children}
        </Head>
    )
}

function renderTitle(title: string) {
    return (
        <>
            <title>{title}</title>
            <meta key="meta og:title" property="og:title" content={title} />
        </>
    )
}

function renderDescription(description: string) {
    return (
        <>
            <meta key="meta description" name="description" content={description} />
            <meta key="meta og:escription" property="og:description" content={description} />
        </>
    )
}

function renderImage(imageUrl: string) {
    return (
        <>
            <meta
                key="meta og:image"
                property="og:image"
                // Twitter card only works with absolute URLs
                content={
                    imageUrl.startsWith('/')
                        ? 'https://' + NEXT_PUBLIC_VERCEL_URL + imageUrl
                        : imageUrl
                }
            />
            <meta key="meta twitter:card" name="twitter:card" content="summary_large_image" />
        </>
    )
}
