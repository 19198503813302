import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * `React.useState` throttled by frame.
 * Use this if you want to set state repeatedly outside of an event handler or effect and want the states to be batched.
 * @see https://github.com/streamich/react-use/blob/v17.3.1/src/useRafState.ts
 *
 * Won't be needed in React v18 anymore as it will batch state updates outside of event handlers and effects as well.
 */
export function useRafState<S>(initialState: S | (() => S)) {
    const frameRef = useRef(0)
    const [state, setState] = useState(initialState)

    const setRafState = useCallback((value: React.SetStateAction<S>) => {
        window.cancelAnimationFrame(frameRef.current)
        frameRef.current = window.requestAnimationFrame(() => setState(value))
    }, [])

    useEffect(() => () => window.cancelAnimationFrame(frameRef.current), [])

    return [state, setRafState] as const
}
