import { Disclosure } from '@headlessui/react'
import clsx from 'clsx'

import { twMerge } from 'src/libs/tailwind-css'

import { HEADING_6_STYLES, PARAGRAPH_SMALL_STYLES } from '../typography-styles'

interface AccordionProps {
    label: React.ReactNode
    children: React.ReactNode
}

export function Accordion({ label, children }: AccordionProps) {
    return (
        <Disclosure as="div" className="grid gap-4">
            <Disclosure.Button
                as="div"
                className="cursor-pointer grid grid-cols-1 grid-flow-col gap-4 md:gap-8"
            >
                {({ open }) => (
                    <>
                        <div className={HEADING_6_STYLES}>{label}</div>
                        <button
                            title={open ? 'Close' : 'Open'}
                            className="group h-[1.6rem] w-[1.6rem] grid place-items-center rounded-[0.6rem] transition-colors hover:bg-blue-100"
                        >
                            <div
                                className={clsx(
                                    'w-[14px] h-[2px] bg-grey-700 rounded-full col-start-1 row-start-1 transition duration-300 group-hover:bg-blue-500',
                                    open ? 'rotate-0' : '-rotate-90'
                                )}
                            />
                            <div
                                className={clsx(
                                    'w-[14px] h-[2px] bg-grey-700 rounded-full col-start-1 row-start-1 transition duration-300 group-hover:bg-blue-500',
                                    open ? 'rotate-180' : 'rotate-0'
                                )}
                            />
                        </button>
                    </>
                )}
            </Disclosure.Button>
            <Disclosure.Panel
                className={twMerge(PARAGRAPH_SMALL_STYLES, 'mr-[2.6rem] md:mr-[3.6rem]')}
            >
                {children}
            </Disclosure.Panel>
        </Disclosure>
    )
}
