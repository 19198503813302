import clsx from 'clsx'
import Link, { LinkProps } from 'next/link'

import { twMerge } from 'src/libs/tailwind-css'

import { BUTTON_LARGE_STYLES, BUTTON_SMALL_STYLES } from '../typography-styles'

type ButtonProps<ElementType extends React.ElementType<GenericElement>> =
    React.ComponentPropsWithoutRef<ElementType> & CustomButtonProps

interface GenericElement {
    className?: string
    children: React.ReactNode
}

interface CustomButtonProps {
    variant?: Variant
    size?: Size
    arrowRight?: boolean
    /**
     * Whether content of secondary button should be aligned at the left edge of surrounding container.
     * This prop is used instead of `className` to prevent accidentally setting px-0 and screwing up the outline.
     */
    alignLeft?: boolean
    loading?: boolean
}

type Variant = 'primary' | 'secondary'
type Size = 'large' | 'small'

export function Button(props: ButtonProps<'button'>) {
    return <button {...getButtonProps(props)} />
}

interface LinkButtonProps
    extends Pick<LinkProps, 'href' | 'replace' | 'prefetch' | 'scroll'>,
        Omit<ButtonProps<'a'>, 'href'> {}

export function LinkButton({ href, replace, scroll, prefetch, ...props }: LinkButtonProps) {
    return (
        <Link href={href} replace={replace} scroll={scroll} prefetch={prefetch}>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a {...getButtonProps(props)} />
        </Link>
    )
}

function getButtonProps<ElementType extends React.ElementType<GenericElement>>({
    variant = 'secondary',
    size = 'small',
    arrowRight,
    className,
    children,
    alignLeft,
    loading,
    ...props
}: ButtonProps<ElementType>) {
    const sizeStyles = SIZE_STYLES[size]

    return {
        ...props,
        className: twMerge(
            'group rounded-[0.7rem] inline-block cursor-pointer disabled:cursor-default disabled:animate-none transition-colors',
            VARIANT_STYLES[variant],
            sizeStyles.button,
            variant === 'secondary' && alignLeft && 'pl-0',
            className
        ),
        children: arrowRight ? (
            <div className="grid grid-flow-col auto-cols-auto items-center justify-center gap-2">
                <div>{children}</div>
                <div className={clsx('overflow-hidden', sizeStyles.arrowRightContainer)}>
                    <div
                        aria-hidden
                        className={clsx(
                            'group-disabled:animate-none relative',
                            loading
                                ? 'animate-slide-fast [animation-direction:reverse]'
                                : 'group-hover:animate-slide group-focus-visible:animate-slide',
                            sizeStyles.arrowRight
                        )}
                    >
                        {'→'}
                        <div
                            className={clsx(
                                'absolute top-0 right-full select-none',
                                sizeStyles.arrowRight
                            )}
                        >
                            {'→'}
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            children
        ),
    }
}

const VARIANT_STYLES: Record<Variant, string> = {
    primary:
        /* tw: */ 'text-white bg-[color:var(--lf-gradient-0,theme(colors.blue.500))] hover:animate-gradient-bg focus-visible:animate-gradient-bg disabled:bg-blue-500/60',
    secondary:
        /* tw: */ 'text-[color:var(--lf-gradient-0,theme(colors.blue.500))] hover:animate-gradient-text focus-visible:animate-gradient-text disabled:text-blue-500/60',
}

interface SizeElementsStyles {
    button: string
    arrowRight: string
    arrowRightContainer: string
}

const SIZE_STYLES: Record<Size, SizeElementsStyles> = {
    large: {
        button: /* tw: */ BUTTON_LARGE_STYLES + ' py-[0.8rem] px-[0.9rem]',
        arrowRight: /* tw: */ 'pr-[0.9rem]',
        arrowRightContainer: /* tw: */ 'mr-[-0.9rem]',
    },
    small: {
        button: /* tw: */ BUTTON_SMALL_STYLES + ' py-[0.5rem] px-[0.8rem]',
        arrowRight: /* tw: */ 'pr-[0.8rem]',
        arrowRightContainer: /* tw: */ 'mr-[-0.8rem]',
    },
}
