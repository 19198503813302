import { twMerge } from 'src/libs/tailwind-css'

export interface SvgIconProps extends React.ComponentPropsWithoutRef<'svg'> {}

export function SvgIcon(props: SvgIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            aria-hidden
            {...props}
            className={twMerge('fill-current', props.className)}
        />
    )
}
