import { useRef } from 'react'

/**
 * Reference of something that gets updated continuously.
 */
export function useNewestRef<T>(thing: T) {
    const thingRef = useRef(thing)
    thingRef.current = thing

    return thingRef
}
