import { forwardRef } from 'react'

import { twMerge } from 'src/libs/tailwind-css'

import { BUTTON_SMALL_STYLES } from './typography-styles'

interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
    error?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
    { error, ...props },
    ref
) {
    return (
        <input
            ref={ref}
            {...props}
            className={twMerge(
                BUTTON_SMALL_STYLES,
                'p-2 rounded-[0.7rem] placeholder-grey-700/40 w-full transition-shadow',
                error ? 'ring ring-red-500' : 'ring-1 ring-grey-700/20',
                props.className
            )}
        />
    )
})
