import { Transition } from '@headlessui/react'
import { cloneElement, useState } from 'react'

import { InView } from './in-view'

interface LazyVideoProps {
    src: string
    width: number
    height: number
    placeholderImage?: React.ReactElement<{ sizes?: string }>
    sizes?: string
    noLoop?: boolean
}

export function LazyVideo({ src, width, height, placeholderImage, sizes, noLoop }: LazyVideoProps) {
    const [shouldLoad, setShouldLoad] = useState(false)
    const [isPlayingVideo, setIsPlayingVideo] = useState(false)

    const aspectRatioPercentage = (100 * height) / width

    return (
        <InView
            onIntersect={({ isIntersecting }) => setShouldLoad(isIntersecting)}
            style={{ paddingBottom: `${aspectRatioPercentage}%` }}
            className="relative w-full max-h-full"
            rootMargin="500px"
        >
            {shouldLoad && (
                <div className="absolute top-0 left-0 w-full h-full">
                    <video
                        src={src}
                        autoPlay
                        muted
                        loop={!noLoop}
                        playsInline
                        className="object-cover"
                        onPlay={() => setIsPlayingVideo(true)}
                    />
                </div>
            )}
            {placeholderImage && (
                <Transition
                    show={!isPlayingVideo}
                    leave="transition-opacity duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="absolute inset-0 w-full"
                >
                    {sizes ? cloneElement(placeholderImage, { sizes }) : placeholderImage}
                </Transition>
            )}
        </InView>
    )
}
