// Copying contents of 'src/libs/styles' here because of Next build error:
// eslint-disable-next-line no-restricted-imports
import 'src/libs/tailwind-css/styles.css'

import type { AppProps } from 'next/app'

import { GlobalWrapper } from 'src/libs/global-wrapper'
import { fontInterRomanVar, fontVaueSerifBold, theme } from 'src/libs/tailwind-css'
import { Analytics } from 'src/libs/analytics'
import { HtmlHead } from 'src/libs/html-head'

export default function App({ Component, pageProps }: AppProps) {
    return (
        <GlobalWrapper>
            <HtmlHead
                title="Laserfocus | The fastest CRM experience ever"
                description="Laserfocus helps you organize your Salesforce pipeline in seconds to close deals more confidently."
                noImageFallback
            >
                <meta
                    name="viewport"
                    // maximum-scale=1 is needed so iOS Safari doesn't zoom into text inputs below 16px
                    content="width=device-width, initial-scale=1, maximum-scale=1"
                />
                {/* Favicon handled according to https://dev.to/masakudamatsu/favicon-nightmare-how-to-maintain-sanity-3al7 */}
                <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <link
                    rel="mask-icon"
                    href="/safari-pinned-tab.svg"
                    color={theme.colors.blue[500]}
                />
                <meta property="og:type" content="website" />
                <link
                    rel="preload"
                    href={fontVaueSerifBold}
                    as="font"
                    type="font/woff2"
                    crossOrigin="anonymous"
                />
                <link
                    rel="preload"
                    href={fontInterRomanVar}
                    as="font"
                    type="font/woff2"
                    crossOrigin="anonymous"
                />
            </HtmlHead>
            <Component {...pageProps} />
            <Analytics />
        </GlobalWrapper>
    )
}
