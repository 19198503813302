import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function SolutionsNaviIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <circle
                cx="6.5"
                cy="10.5"
                r="6.5"
                fill="#D1ABFF"
                style={{ mixBlendMode: 'multiply' }}
            />
            <circle
                cx="16.5"
                cy="3.5"
                r="3.5"
                fill="#FFA294"
                style={{ mixBlendMode: 'multiply' }}
            />
            <circle
                cx="13.5"
                cy="14.5"
                r="5.5"
                fill="#FFDEA1"
                style={{ mixBlendMode: 'multiply' }}
            />
        </SvgIcon>
    )
}
