import type { Analytics, Options } from '@segment/analytics-next'
import { v4 as uuid } from '@lukeed/uuid'

import { NEXT_PUBLIC_SEGMENT_WRITE_KEY } from '../config'
import { localStorageGet, localStorageSet } from '../utils'

type CommandQueueElementMap = {
    [K in keyof Analytics]: {
        command: K
        args: Parameters<Analytics[K]>
    }
}
type CommandQueueElement = CommandQueueElementMap[keyof CommandQueueElementMap]

let analytics: Analytics | undefined
let commandQueue: CommandQueueElement[] = []
let didCallLoad = false

export async function load() {
    if (didCallLoad) {
        return
    }

    didCallLoad = true

    const { AnalyticsBrowser } = await import(
        // Using different name on purpose in case some content blockers block URLs with "analytics" in them.
        /* webpackChunkName: "analiticzzz" */
        /* webpackExports: ["AnalyticsBrowser"] */
        '@segment/analytics-next'
    )
    const [analyticsClient] = await AnalyticsBrowser.load(
        { writeKey: NEXT_PUBLIC_SEGMENT_WRITE_KEY },
        { initialPageview: false }
    )

    analytics = analyticsClient

    commandQueue.forEach((element) => analyticsClient[element.command](...element.args))
    commandQueue = []
}

interface PagePropsWithoutCategory {
    category?: undefined
    name?: string
    properties?: object
    options?: Options
}
/** When category is defined, name needs to be defined as well */
interface PagePropsWithCategory {
    category: string
    name: string
    properties?: object
    options?: Options
}

export async function page({
    category,
    name,
    // Properties need default value, otherwise options object will be used as properties object instead within analytics client.
    properties = {},
    options,
}: PagePropsWithoutCategory | PagePropsWithCategory = {}) {
    if (analytics) {
        return analytics.page(category, name, properties, options)
    }

    commandQueue.push({
        command: 'page',
        args: [
            category,
            name,
            properties,
            {
                ...options,
                timestamp: options?.timestamp || new Date(),
            },
        ],
    })
}

export function getAnonymousId() {
    if (analytics) {
        return analytics.user().anonymousId()
    }

    const idFromLocalStorage = localStorageGet('ajs_anonymous_id')

    if (idFromLocalStorage) {
        return idFromLocalStorage
    }

    const anonymousId = uuid()

    commandQueue.push({
        command: 'setAnonymousId',
        args: [anonymousId],
    })
    window.setTimeout(() => localStorageSet('ajs_anonymous_id', anonymousId))

    return anonymousId
}
