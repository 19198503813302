import { Transition } from '@headlessui/react'

// import { TransitionQueueHandlerConcurrent } from './transition-queue-handler-concurrent'
// import { TransitionQueueHandlerInOut } from './transition-queue-handler-in-out'
import { TransitionQueueHandlerOutIn } from './transition-queue-handler-out-in'
import { InternalSwitchTransitionProps, SwitchTransitionProps } from './types'
import { useTransitionQueueRef } from './use-transition-queue'

/**
 * Transitions between two elements. Has exactly the same API as [Transition from @headlessui/react](https://headlessui.dev/react/transition) with two differences:
 * 1. `show` prop has no effect. If you want to hide an element, pass `null` as children.
 * 2. Component is animating between children only when they aren't *equal* to the previous children. Not being equal means that the `key` of the child element changes or if no key can be found, the children reference changes.
 */
export const SwitchTransition: typeof Transition = (
    // {
    //     mode = 'out-in',
    //     ...props
    // }: SwitchTransitionProps
    props: SwitchTransitionProps
) => {
    const transitionQueueRef = useTransitionQueueRef(props as InternalSwitchTransitionProps)

    // Disabled as we don't use them right now and don't want to ship them within the JS bundle unnecessarily
    // const TransitionQueueHandler = {
    //     'out-in': TransitionQueueHandlerOutIn,
    //     'in-out': TransitionQueueHandlerInOut,
    //     concurrent: TransitionQueueHandlerConcurrent,
    // }[mode]

    // return <TransitionQueueHandler transitionQueueRef={transitionQueueRef} />

    return <TransitionQueueHandlerOutIn transitionQueueRef={transitionQueueRef} />
}

SwitchTransition.Child = Transition.Child
SwitchTransition.Root = SwitchTransition
