import React from 'react'
import clsx from 'clsx'

import { CloseOutlinedIcon } from 'src/libs/icons'
import { Button } from 'src/libs/design-system'

interface ModalHeaderProps {
    children: React.ReactNode
    close?(): void
    border?: boolean
}

export function ModalHeader({ children, close, border }: ModalHeaderProps) {
    return (
        <div
            // relative: Needed to prevent background of inputs being in front of boxShadow of this element (Why is this happening?)
            className={clsx(
                'relative px-8 pt-8 pb-4 grid grid-flow-col grid-cols-1 items-start gap-4',
                border && 'border-b bg-clip-padding border-grey-700/10'
            )}
        >
            <h1 className="font-serif text-[2rem] leading-[1.2]">{children}</h1>
            <Button type="button" title="Close" onClick={close} className="my-1">
                <CloseOutlinedIcon className="w-4 h-4" />
            </Button>
        </div>
    )
}
