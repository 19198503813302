import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function FeaturesNaviIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <circle cx="12.5" cy="12.5" r="7.5" fill="#96B5F9" />
            <circle cx="7.5" cy="7.5" r="7.5" fill="#A5F9CD" style={{ mixBlendMode: 'multiply' }} />
        </SvgIcon>
    )
}
