import { Transition } from '@headlessui/react'
import { useRef } from 'react'

import { useReRender } from '../use-re-render'

import { TransitionQueueHandlerProps } from './types'

export function TransitionQueueHandlerConcurrent({
    transitionQueueRef,
}: TransitionQueueHandlerProps) {
    const reRender = useReRender()

    // We need to use refs for these instead of local variables because it could be that a re-render happens between setting them.
    const firstLeftRef = useRef(false)
    const secondEnteredRef = useRef(false)

    const firstQueued = transitionQueueRef.current[0]

    if (transitionQueueRef.current.length === 1) {
        return <Transition key={firstQueued.key} {...firstQueued.props} show={true} />
    }

    function afterTransition() {
        if (!firstLeftRef.current || !secondEnteredRef.current) {
            return
        }

        firstLeftRef.current = false
        secondEnteredRef.current = false

        if (transitionQueueRef.current.length === 2) {
            transitionQueueRef.current = [transitionQueueRef.current[1]!]
        } else {
            // Skip all elements in queue which are out of date and not yet entered
            transitionQueueRef.current = [
                transitionQueueRef.current[1]!,
                transitionQueueRef.current[transitionQueueRef.current.length - 1]!,
            ]
        }

        reRender()
    }

    const firstQueuedProps = firstQueued.props
    const secondQueued = transitionQueueRef.current[1]!
    const secondQueuedProps = secondQueued.props

    return (
        <>
            <Transition
                {...firstQueuedProps}
                key={firstQueued.key}
                show={false}
                // Skip leave animation if no children
                leave={
                    firstQueuedProps.children || firstQueuedProps.children === 0
                        ? firstQueuedProps.leave
                        : undefined
                }
                afterLeave={() => {
                    firstLeftRef.current = true
                    afterTransition()
                }}
            />
            <Transition
                {...secondQueuedProps}
                key={secondQueued.key}
                show={true}
                appear
                // Skip enter animation if no children
                enter={
                    secondQueuedProps.children || secondQueuedProps.children === 0
                        ? secondQueuedProps.enter
                        : undefined
                }
                afterEnter={() => {
                    secondEnteredRef.current = true
                    afterTransition()
                }}
            />
        </>
    )
}
