import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function CalendarOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M8 2a1 1 0 0 1 1 1v1h6V3a1 1 0 1 1 2 0v1h2a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H5a2.99 2.99 0 0 1-3.02-3V7a3 3 0 0 1 3-3H7V3a1 1 0 0 1 1-1ZM7 6H4.98a1 1 0 0 0-1 1v12a1 1 0 0 0 1 .99 1 1 0 0 1 .15.01h13.86a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H17v1a1 1 0 1 1-2 0V6H9v1a1 1 0 0 1-2 0V6Zm-1 5a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Z" />
        </SvgIcon>
    )
}
