import { cloneElement } from 'react'

import { Appear } from 'src/libs/react-utils'
import { theme, twMerge, useIsMinWidthMd } from 'src/libs/tailwind-css'

import { Layout2Elements } from './layout-2-elements'

interface Layout2ElementsWithImageProps {
    image: React.ReactElement<{ className?: string; sizes?: string }>
    element: React.ReactNode
    imageShadow?: boolean
    swapElementsWhenVertical?: boolean
}

export function Layout2ElementsWithImage({
    image,
    element,
    imageShadow,
    swapElementsWhenVertical,
}: Layout2ElementsWithImageProps) {
    const isMinWidthMd = useIsMinWidthMd()

    const appearImage = (
        <Appear
            classNameVisible="transition-all"
            styleHidden={{
                clipPath: `inset(15% 15% 15% 15% round ${isMinWidthMd ? 2.5 : 1.5}rem)`,
            }}
            styleVisible={{
                clipPath: `inset(0 0 0 0 round ${isMinWidthMd ? 2.5 : 1.5}rem)`,
            }}
        >
            {cloneElement(image, {
                className: twMerge('w-full', image.props.className),
                sizes: `(min-width: ${theme.screens.sm}) 24rem, 100vw`,
            })}
        </Appear>
    )

    return (
        <Layout2Elements
            first={
                imageShadow ? (
                    // Safari only works with one single drop-shadow here, but I don't know why
                    <div style={{ filter: 'drop-shadow(rgba(0, 0, 0, 0.065) 0px 12.5px 20px)' }}>
                        {appearImage}
                    </div>
                ) : (
                    appearImage
                )
            }
            second={<Appear>{element}</Appear>}
            swapElementsWhenVertical={swapElementsWhenVertical}
        />
    )
}
