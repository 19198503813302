import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import { CloseOutlinedIcon } from 'src/libs/icons'
import { twMerge } from 'src/libs/tailwind-css'

import { Button } from '../button/button'

export function ModalOverlay({ className }: { className?: string }) {
    return (
        <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <Dialog.Overlay className={twMerge('fixed inset-0 w-full bg-black/70', className)} />
        </Transition.Child>
    )
}

export function ModalOverlayClose({ close }: { close(): void }) {
    return (
        <div className="fixed right-3 top-3">
            <Button
                type="button"
                title="Close"
                onClick={close}
                className="hover:bg-white/10 focus-visible:outline-0 focus-visible:ring-1"
            >
                <CloseOutlinedIcon className="w-6 h-6 text-white " />
            </Button>
        </div>
    )
}
