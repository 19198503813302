import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function CreditCardOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                d="M3 5a1 1 0 0 0-1 1v3h20V6a1 1 0 0 0-1-1H3ZM0 6v12a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3H3a3 3 0 0 0-3 3Zm22 12v-7H2v7a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1Zm-6-3a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2h-3Z"
            />
        </SvgIcon>
    )
}
