import { useState } from 'react'

import { twMerge } from 'src/libs/tailwind-css'

import { isAppHydratedRef } from '../utils'

import { InView } from './in-view'

interface AppearProps extends React.ComponentPropsWithoutRef<'div'> {
    classNameVisible?: string
    classNameHidden?: string
    styleVisible?: React.CSSProperties
    styleHidden?: React.CSSProperties
    rootMarginVisible?: string
    rootMarginHidden?: string
}

export function Appear({
    className,
    classNameVisible,
    classNameHidden,
    style,
    styleVisible,
    styleHidden,
    rootMarginVisible = '1000000px 0px 100px 0px',
    rootMarginHidden = '1000000px 0px -15% 0px',
    ...props
}: AppearProps) {
    const [isVisible, setIsVisible] = useState(!isAppHydratedRef.current)

    const styleOverrides = isVisible ? styleVisible : styleHidden
    const finalStyle =
        style && styleOverrides ? { ...style, ...styleOverrides } : style || styleOverrides

    return (
        <InView
            {...props}
            rootMargin={isVisible ? rootMarginVisible : rootMarginHidden}
            style={finalStyle}
            className={twMerge(
                isVisible
                    ? 'transition duration-700 translate-y-0 opacity-1'
                    : 'translate-y-12 opacity-0',
                className,
                isVisible ? classNameVisible : classNameHidden
            )}
            onIntersect={({ isIntersecting }) => setIsVisible(isIntersecting)}
        />
    )
}
