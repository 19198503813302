import { useEffect, useState } from 'react'

import { isAppHydratedRef } from 'src/libs/utils'

/**
 * Subscribe to whether we're matching a media query.
 * @see https://github.com/streamich/react-use/blob/v17.3.1/src/useMedia.ts
 */
export function useMedia(query: string, hydrationState = false) {
    const [isMatching, setIsMatching] = useState(
        isAppHydratedRef.current ? () => window.matchMedia(query).matches : hydrationState
    )

    useEffect(() => {
        const mediaQueryList = window.matchMedia(query)

        function onChange(event: MediaQueryListEvent) {
            setIsMatching(event.matches)
        }

        mediaQueryList.addEventListener('change', onChange, { passive: true })

        setIsMatching(mediaQueryList.matches)

        return () => mediaQueryList.removeEventListener('change', onChange)
    }, [query])

    return isMatching
}
