// Important! Only add font styles here

export const BUTTON_LARGE_STYLES = 'text-[0.9rem] leading-[1.2] font-semibold'
export const BUTTON_SMALL_STYLES = 'text-[0.8rem] leading-[1.2] font-semibold'
export const LABEL_STYLES = 'text-[0.8rem] leading-[1.2] font-medium'
export const HELPER_TEXT_STYLES = 'text-[0.7rem] leading-normal font-normal'
export const HEADING_1_STYLES = 'font-serif font-bold text-[2.5rem] leading-[1.1] md:text-[3.5rem]'
export const HEADING_2_STYLES = 'font-serif font-bold text-[2rem] leading-[1.1] md:text-[2.3rem]'
export const HEADING_3_STYLES = 'font-serif font-bold text-[1.7rem] leading-[1.2] md:text-[1.9rem]'
export const HEADING_4_STYLES = 'font-serif font-bold text-2xl leading-[1.3]'
export const HEADING_5_STYLES = 'font-serif font-bold text-[1.3rem] leading-[1.3]'
export const HEADING_6_STYLES = 'font-serif font-bold text-[1.1rem] leading-[1.3]'
export const PARAGRAPH_INTRO_STYLES = 'text-[1.2rem] leading-normal'
export const PARAGRAPH_SMALL_STYLES = 'text-[0.9rem] leading-normal'
export const PARAGRAPH_STYLES = 'text-base'
export const MENU_STYLES = 'text-[0.8rem] leading-[1.2]'
export const CAPTION_STYLES = 'text-[0.9rem] italic font-light'
