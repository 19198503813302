import 'focus-visible'

import { useIsomorphicLayoutEffect } from 'src/libs/react-utils'
import { isAppHydratedRef } from 'src/libs/utils'

interface GlobalWrapperProps {
    children: React.ReactNode
    noAnalytics?: boolean
}

/**
 * Component which wraps entire app. This is the place to put global React-specific logic.
 */
export function GlobalWrapper({ children }: GlobalWrapperProps) {
    // We're using a layout effect because it fires before normal effects. This way the variable is set to true already once effects run.
    useIsomorphicLayoutEffect(() => {
        isAppHydratedRef.current = true
    }, [])

    return <>{children}</>
}
