import { useEffect, useState } from 'react'

import { isAppHydratedRef } from 'src/libs/utils'

const ID_DURING_HYDRATION = 0

export function useUniqueId(prefix: string) {
    const [uniqueId, setUniqueId] = useState(
        isAppHydratedRef.current ? getUniqueId : ID_DURING_HYDRATION
    )

    useEffect(() => {
        if (uniqueId === ID_DURING_HYDRATION) {
            setUniqueId(getUniqueId())
        }
    }, [uniqueId])

    return `${prefix}-${uniqueId}`
}

let currentId = ID_DURING_HYDRATION + 1

function getUniqueId() {
    return currentId++
}
