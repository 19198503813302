import { twMerge } from 'src/libs/tailwind-css'

import { CONTENT_CONTAINER_STYLES } from './content-container'

interface Layout2ElementsProps {
    first: React.ReactNode
    second: React.ReactNode
    swapElementsWhenVertical?: boolean
}

export function Layout2Elements({ first, second, swapElementsWhenVertical }: Layout2ElementsProps) {
    return (
        <div
            className={twMerge(
                CONTENT_CONTAINER_STYLES,
                'grid gap-y-8 gap-x-[8%] grid-cols-1 lg:grid-cols-2 items-center'
            )}
        >
            <div className={swapElementsWhenVertical ? 'lg:order-2' : undefined}>{first}</div>
            <div>{second}</div>
        </div>
    )
}
