import { useEffect, useState } from 'react'

import { isAppHydratedRef, IS_BROWSER } from 'src/libs/utils'

export const IS_SAFARI =
    IS_BROWSER &&
    navigator.vendor?.includes('Apple') &&
    navigator.userAgent?.includes('Safari') &&
    !navigator.userAgent?.includes('Chrome') &&
    !navigator.userAgent?.includes('Firefox')

/** Safari is the new IE. https://safari-is-the-new-ie.com */
export function useIsSafari() {
    // We must set the `isSafari` variable to the same value during client-side hydration as on the server-side render.
    const [isSafari, setIsSafari] = useState(isAppHydratedRef.current ? IS_SAFARI : false)

    useEffect(() => setIsSafari(IS_SAFARI), [])

    return isSafari
}
