/**
 * Checking for missing envrionment variables.
 * All checks must check for envrionemnt variable directly with `process.env.SOMETHING === 'some-literal'`.
 * Please don't use variables and also don't use `[…].includes(process.env.SOMETHING)`.
 * The reason is that `process.env.SOMETHING` gets replaced with the literal value and minification will remove those if statements completely from the bundle if there is an expression like `'some-literal' === 'some-other-literal'`.
 */

if (
    process.env.NEXT_PUBLIC_ENV !== 'production' &&
    process.env.NEXT_PUBLIC_ENV !== 'preview' &&
    process.env.NEXT_PUBLIC_ENV !== 'development' &&
    process.env.NEXT_PUBLIC_ENV !== 'ci'
) {
    throw Error('process.env.NEXT_PUBLIC_ENV is invalid')
}

if (
    (process.env.NEXT_PUBLIC_ENV === 'production' || process.env.NEXT_PUBLIC_ENV === 'preview') &&
    !process.env.NEXT_PUBLIC_SENTRY_DSN
) {
    throw Error('process.env.NEXT_PUBLIC_SENTRY_DSN is missing')
}

if (
    (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ||
        process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' ||
        process.env.NEXT_PUBLIC_VERCEL_ENV === 'development') &&
    !process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY
) {
    throw Error('process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY is missing')
}

export const NEXT_PUBLIC_SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY as string

if (
    (process.env.NEXT_PUBLIC_ENV === 'production' || process.env.NEXT_PUBLIC_ENV === 'preview') &&
    !process.env.NEXT_PUBLIC_VERCEL_URL
) {
    throw Error('process.env.NEXT_PUBLIC_VERCEL_URL is missing')
}

export const NEXT_PUBLIC_VERCEL_URL = process.env.NEXT_PUBLIC_VERCEL_URL || 'laserfocus.io'
