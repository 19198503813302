import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef } from 'react'

import { ModalContainer } from './modal-container'
import { ModalHeader } from './modal-header'
import { ModalOverlay, ModalOverlayClose } from './modal-overlay'

interface ModalProps {
    show: boolean
    onClose(value: boolean): void
    children: React.ReactNode
}

export function Modal({ show, onClose, children }: ModalProps) {
    const lastShownChildrenRef = useRef(children)

    if (show) {
        lastShownChildrenRef.current = children
    }

    return (
        <Transition show={show} appear as={Fragment}>
            <Dialog onClose={onClose} className="fixed z-10 inset-0 overflow-y-auto">
                {lastShownChildrenRef.current}
            </Dialog>
        </Transition>
    )
}

Modal.Container = ModalContainer
Modal.Header = ModalHeader
Modal.Overlay = ModalOverlay
Modal.OverlayClose = ModalOverlayClose
