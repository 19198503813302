import { Transition } from '@headlessui/react'
import { useEffect, useState } from 'react'
import Link from 'next/link'

import { Button, PARAGRAPH_SMALL_STYLES } from 'src/libs/design-system'

interface CookieBannerProps {
    onSubmit(hasAccepted: boolean): void
}

const BANNER_SHOW_DELAY = 3000

export function CookieBanner({ onSubmit }: CookieBannerProps) {
    const [isVisible, setIsVisible] = useState(false)
    const [choice, setChoice] = useState<boolean>()

    const didChoose = typeof choice === 'boolean'

    useEffect(() => {
        const timeout = window.setTimeout(() => setIsVisible(true), BANNER_SHOW_DELAY)
        return () => window.clearTimeout(timeout)
    }, [])

    return (
        <Transition
            show={isVisible && !didChoose}
            enter="transition-transform duration-300"
            enterFrom="translate-y-[calc(100%+2rem)]"
            enterTo="translate-y-0"
            leave="transition-transform duration-300"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-[calc(100%+2rem)]"
            className="fixed bottom-0 left-0 w-full px-4 pb-4 pointer-events-none"
            afterLeave={didChoose ? () => onSubmit(choice) : undefined}
        >
            <article className="max-w-sm mx-auto bg-white rounded-3xl shadow-2xl p-4 grid gap-2 pointer-events-auto">
                <div className={PARAGRAPH_SMALL_STYLES}>
                    {'This website uses cookies to provide you the best experience. Read our '}
                    <Link href="/privacy-policy">
                        <a className="underline">Privacy Policy</a>
                    </Link>
                    {' to learn more.'}
                </div>
                <div className="grid grid-flow-col justify-end gap-2">
                    <Button onClick={() => setChoice(false)}>Please, no</Button>
                    <Button variant="primary" arrowRight onClick={() => setChoice(true)}>
                        I accept
                    </Button>
                </div>
            </article>
        </Transition>
    )
}
