import marblesDistributedOnPathsVideoUrl from './marbles-distributed-on-paths-video.mp4'
import marblesFinishLineVideoUrl from './marbles-finish-line-video.mp4'
import marblesInLoopingVideoUrl from './marbles-in-looping-video.mp4'
import marblesOnHelixSlideVideoUrl from './marbles-on-helix-slide-video.mp4'
import marblesSlideFasterVideoUrl from './marbles-slide-faster-video.mp4'
import pielineTableVideoUrl from './pipeline-table-RF30-video.mp4'
import stackCardsVideoUrl from './stack-cards-RF30-video.mp4'
import quickDemoThumbnail from './thumbnail/quick-demo-video-thumbnail.jpeg'

export const marblesDistributedOnPathsVideo = {
    src: marblesDistributedOnPathsVideoUrl,
    width: 800,
    height: 1080,
}
export const marblesFinishLineVideo = {
    src: marblesFinishLineVideoUrl,
    width: 1000,
    height: 800,
}
export const marblesInLoopingVideo = {
    src: marblesInLoopingVideoUrl,
    width: 1080,
    height: 1080,
}
export const marblesOnHelixSlideVideo = {
    src: marblesOnHelixSlideVideoUrl,
    width: 800,
    height: 1080,
}
export const marblesSlideFasterVideo = {
    src: marblesSlideFasterVideoUrl,
    width: 800,
    height: 1080,
}
export const pipelineTableVideo = {
    src: pielineTableVideoUrl,
    width: 1116,
    height: 1000,
}
export const stackCardsVideo = {
    src: stackCardsVideoUrl,
    width: 1116,
    height: 1000,
}

export const quickDemoVideo = {
    url: 'https://laserfocus-website.pages.dev/lf-demo-video.mp4',
    thumbnail: quickDemoThumbnail,
}
