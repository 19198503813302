import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function CheckFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M20.06 5.94a1.5 1.5 0 0 0-2.12 0L9 14.88l-2.94-2.94a1.5 1.5 0 0 0-2.12 2.12l4 4a1.5 1.5 0 0 0 2.12 0l10-10a1.5 1.5 0 0 0 0-2.12Z" />
        </SvgIcon>
    )
}
