import { theme } from './theme'

const COLORS = [
    theme.colors.blue[500],
    theme.colors.green[500],
    theme.colors.yellow[300],
    theme.colors.purple[400],
]

export function getRotatedGradientColors(startWith: 'blue' | 'green' | 'yellow' | 'purple') {
    const firstColor = { blue: 0, green: 1, yellow: 2, purple: 3 }[startWith]

    return {
        '--lf-gradient-0': COLORS[firstColor]!,
        '--lf-gradient-25': COLORS[(firstColor + 1) % COLORS.length]!,
        '--lf-gradient-50': COLORS[(firstColor + 2) % COLORS.length]!,
        '--lf-gradient-75': COLORS[(firstColor + 3) % COLORS.length]!,
    }
}
