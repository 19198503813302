import { useMedia } from 'src/libs/react-utils'
// import { isAppHydratedRef } from 'src/libs/util'

import { theme } from '.'

export function useIsMinWidthSm(hydrationState?: boolean) {
    return useMedia(`(min-width: ${theme.screens.sm})`, hydrationState)
}

export function useIsMinWidthMd(hydrationState?: boolean) {
    return useMedia(`(min-width: ${theme.screens.md})`, hydrationState)
}

export function useIsMinWidthLg(hydrationState?: boolean) {
    return useMedia(`(min-width: ${theme.screens.lg})`, hydrationState)
}

// export function useIsMinWidthXl(hydrationState?: boolean) {
//     return useMedia(`(min-width: ${theme.screens.xl})`, hydrationState)
// }

export function useIsMinWidth3xl(hydrationState?: boolean) {
    return useMedia(`(min-width: ${theme.screens['3xl']})`, hydrationState)
}

// type RemSize = 18 | 20 | 22

// export function useRemSize(hydrationState: RemSize): RemSize
// export function useRemSize(hydrationState?: RemSize): RemSize | undefined {
//     const isMinWidthXl = useIsMinWidthXl()
//     const isMinWidthMd = useIsMinWidthMd()

//     if (!isAppHydratedRef.current) {
//         return hydrationState
//     }
//     if (isMinWidthXl) {
//         return 22
//     }
//     if (isMinWidthMd) {
//         return 20
//     }
//     return 18
// }

export function isMinWidthXl(windowWidth: number) {
    return windowWidth >= Number.parseInt(theme.screens.xl)
}
