import { useEffect, useState } from 'react'

import { isAppHydratedRef } from 'src/libs/utils'

export function useIsAppHydrated() {
    const [isAppHydrated, setIsAppHydrated] = useState(isAppHydratedRef.current)

    useEffect(() => setIsAppHydrated(true), [])

    return isAppHydrated
}
