import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function BlogNaviIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <circle cx="4.5" cy="5.5" r="4.5" fill="#D1ABFF" style={{ mixBlendMode: 'multiply' }} />
            <circle cx="10" cy="12" r="7" fill="#FFA294" style={{ mixBlendMode: 'multiply' }} />
            <circle
                cx="15.5"
                cy="5.5"
                r="4.5"
                fill="#FFDEA1"
                style={{ mixBlendMode: 'multiply' }}
            />
        </SvgIcon>
    )
}
