import { useEffect } from 'react'

import { isAppHydratedRef } from 'src/libs/utils'

import { useRafState } from './use-raf-state'

/**
 * Subscribe to window dimnesions.
 * @see https://github.com/streamich/react-use/blob/v17.3.1/src/useWindowSize.ts
 */
export function useWindowSize(hydrationWidth = Infinity, hydrationHeight = Infinity) {
    const [size, setSize] = useRafState(() => ({
        width: isAppHydratedRef.current ? window.innerWidth : hydrationWidth,
        height: isAppHydratedRef.current ? window.innerHeight : hydrationHeight,
    }))

    useEffect(() => {
        function onResize() {
            setSize((previousSize) => {
                const nextSize = {
                    width: window.innerWidth,
                    height: window.innerHeight,
                }

                if (
                    previousSize.width === nextSize.width &&
                    previousSize.height === nextSize.height
                ) {
                    return previousSize
                }
                return nextSize
            })
        }

        window.addEventListener('resize', onResize, { passive: true })

        onResize()

        return () => window.removeEventListener('resize', onResize)
    }, [setSize])

    return size
}
