import { useRef } from 'react'

import { useIsomorphicLayoutEffect } from './use-isomorphic-layout-effect'
import { useNewestRef } from './use-newest-ref'

type ExtractKeysOfValueType<T, K> = { [I in keyof T]: T[I] extends K ? I : never }[keyof T]

type Root = React.RefObject<Element> | ExtractKeysOfValueType<HTMLElement, Element | null> | null

interface InViewProps
    extends React.ComponentPropsWithoutRef<'div'>,
        UseIntersectionObservedRefProps {}

export function InView({
    root,
    rootMargin,
    threshold,
    disabled,
    onIntersect,
    ...props
}: InViewProps) {
    const nodeRef = useIntersectionObservedRef<HTMLDivElement>({
        root,
        rootMargin,
        threshold,
        disabled,
        onIntersect,
    })

    return <div ref={nodeRef} {...props} />
}

interface UseIntersectionObservedRefProps {
    root?: Root
    rootMargin?: string
    threshold?: number | number[]
    disabled?: boolean
    onIntersect(entry: IntersectionObserverEntry): void
}

export function useIntersectionObservedRef<T extends HTMLElement>({
    root,
    rootMargin,
    threshold,
    disabled,
    onIntersect,
}: UseIntersectionObservedRefProps) {
    const onIntersectRef = useNewestRef(onIntersect)

    const nodeRef = useRef<T>(null)

    useIsomorphicLayoutEffect(() => {
        const node = nodeRef.current

        if (!node || disabled) {
            return
        }

        let isFirstCallback = true

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (isFirstCallback) {
                    isFirstCallback = false

                    const { x, y, width, height } = entry!.boundingClientRect

                    if (!x && !y && !width && !height) {
                        // Forces callback to be called again
                        observer.unobserve(node)
                        observer.observe(node)
                        return
                    }
                }

                return onIntersectRef.current(entry!)
            },
            {
                root: getRootElement(root, node),
                rootMargin,
                threshold,
            }
        )

        observer.observe(node)
        return () => observer.unobserve(node)
    }, [disabled, root, rootMargin, threshold, onIntersectRef])

    return nodeRef
}

function getRootElement(root: Root | undefined, node: HTMLElement) {
    if (typeof root === 'string') {
        return node[root]
    }
    return root?.current
}
