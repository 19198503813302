import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useLocalStorage } from 'src/libs/react-utils'
import { requestIdleCallback } from 'src/libs/utils'

import { load, page } from './analytics-commands'
import { CookieBanner } from './cookie-banner'

export function Analytics() {
    const [hasAcceptedAnalytics, setHasAcceptedAnalytics] = useLocalStorage(
        'lf:has-accepted-analytics'
    )

    useEffect(() => {
        if (hasAcceptedAnalytics === 'true') {
            requestIdleCallback(load)
        }
    }, [hasAcceptedAnalytics])

    const router = useRouter()

    useEffect(() => {
        page()
    }, [router.pathname])

    if (hasAcceptedAnalytics === 'false' || hasAcceptedAnalytics === 'true') {
        return null
    }

    return (
        <CookieBanner onSubmit={(hasAccepted) => setHasAcceptedAnalytics(hasAccepted.toString())} />
    )
}
