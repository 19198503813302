import { useUniqueId } from 'src/libs/react-utils'

import { SvgIcon, SvgIconProps } from '../svg-icon'

interface laserfocusLogoIcon extends SvgIconProps {
    /** ID which needs to be unique per component use */
    usageId: string
}

export function LaserfocusLogoIcon({ usageId, ...props }: laserfocusLogoIcon) {
    const gradientId = useUniqueId(`laserfocus-logo-linear-gradient-${usageId}`)

    return (
        <SvgIcon viewBox="0 0 80 80" {...props}>
            <circle cx="40" cy="40" r="40" fill="#0E17B5" />
            <circle cx="40" cy="40" r="32.3478" fill={`url(#${gradientId})`} />
            <circle cx="40" cy="40" r="22.9565" fill="#EDEFF9" />
            <defs>
                <linearGradient
                    id={gradientId}
                    x1="40"
                    y1="7.65218"
                    x2="40"
                    y2="72.3478"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1146EB" />
                    <stop offset="1" stopColor="#0E17B5" />
                </linearGradient>
            </defs>
        </SvgIcon>
    )
}
