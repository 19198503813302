import { Transition } from '@headlessui/react'

import { TransitionQueueHandlerConcurrent } from './transition-queue-handler-concurrent'
import { InternalSwitchTransitionProps, SwitchTransitionProps } from './types'
import { useTransitionQueueRef } from './use-transition-queue'

/**
 * Transitions between two elements. Has exactly the same API as [Transition from @headlessui/react](https://headlessui.dev/react/transition) with two differences:
 * 1. `show` prop has no effect. If you want to hide an element, pass `null` as children.
 * 2. Component is animating between children only when they aren't *equal* to the previous children. Not being equal means that the `key` of the child element changes or if no key can be found, the children reference changes.
 */
export const SwitchTransitionConcurrent: typeof Transition = (props: SwitchTransitionProps) => {
    const transitionQueueRef = useTransitionQueueRef(props as InternalSwitchTransitionProps)
    return <TransitionQueueHandlerConcurrent transitionQueueRef={transitionQueueRef} />
}

SwitchTransitionConcurrent.Child = Transition.Child
SwitchTransitionConcurrent.Root = SwitchTransitionConcurrent
